import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'

export default function MenuDesplegable() { 
    return (
        <div>
            <button className='toggle-button'>
                <Menu menuButton={<MenuButton className='toggle'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                </MenuButton>}>
                    <div className='menu-item'>
                        <MenuItem className='item'>Inicio</MenuItem>
                        <MenuItem className='item'>Acerca de</MenuItem>
                        <MenuItem className='item'>Servicios</MenuItem>
                    </div>
                </Menu>
            </button>
        </div>
    )
}